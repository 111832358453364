import React, { useState } from "react";
import { useRouter } from "next/router";
import { CognitoUser } from "@aws-amplify/auth";

// ui
import { SignInForm } from "./SignInForm";
import { getAPIUserSummary } from "@/api/api-users/useCurrentUser";
import { getEnvConfig } from "@/config/environments";
import { newAPIClient } from "@/libs/isUserAuthenticated";
import { setSession } from "@/services/authService";
import { SetPasswordForm } from "../set-password/SetPasswordView";
import { FullScreenLoader } from "@/components";
import { MFACodeView } from "../mfa";

export const SignInView = () => {
  const router = useRouter();

  const secondaryView = router?.query["v"];

  const isNewUser = secondaryView === "set-password";
  const isMFA = secondaryView === "2fa-code";

  const to = router?.query?.to;
  const config = getEnvConfig();
  const [user, setUser] = useState<CognitoUser>();

  const code = router?.query?.code;

  if (code) {
    const { origin, pathname } = window.location;
    fetch(
      `${config.cognitoDomain}/oauth2/token?code=${code}&grant_type=authorization_code&client_id=${
        config.cognitoClientId
      }&redirect_uri=${encodeURIComponent(`${origin}${pathname}`)}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        method: "POST",
      }
    ).then((resp) => {
      resp.json().then(({ access_token: token, id_token }) => {
        if (!token) return;

        setSession(token);
        // Check if user is in db
        const api = newAPIClient(token);
        getAPIUserSummary(api).then(() => {
          router.push(to?.toString() ?? "/app");
        });
      });
    });

    return <FullScreenLoader />;
  }

  return (
    <>
      <div className="flex flex-col h-screen relative">
        <div className="flex flex-col items-center justify-center flex-1">
          <div className="flex flex-col max-w-xs w-full px-6 items-center justify-center flex-1 min-h-24">
            <div className="items-center w-full mx-auto">
              {isNewUser ? (
                <SetPasswordForm to={to?.toString()} user={user} />
              ) : isMFA ? (
                <MFACodeView user={user} />
              ) : (
                <SignInForm onSignIn={setUser} to={to?.toString()} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignInView;
